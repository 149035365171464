import React, { useState, useEffect } from 'react'
import { Fade } from 'react-reveal'

const Name = () => {
	const [emoji, setEmoji] = useState(true)
	useEffect(() => {
		setEmoji(false)
		setTimeout(() => {
			setEmoji(true)
		}, 4000)
	}, [])
	return (
		<div className='me'>
			<svg className='name' viewbox='0 0 1802 362'>
				<text x='50%' y='50%' dy='.35em' text-anchor='middle'>
					HI I'M ADRIAN
				</text>
			</svg>
			{emoji && (
				<Fade className='emoji' right>
					<i className='fas fa-hand-peace'></i>
				</Fade>
			)}
		</div>
	)
}

export default Name
