import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import HomeScreen from './screens/HomeScreen'
import AboutScreen from './screens/AboutScreen'
import NotFound from './screens/NotFound'
// import ContactScreen from './screens/ContactScreen'
import Footer from './components/Footer'
// import Nav from './components/Nav'

const App = () => {
	return (
		<Router>
			{/* <Nav /> */}
			<main>
				<Route
					render={({ location }) => (
						<AnimatePresence exitBeforeEnter initial={false}>
							<Switch location={location} key={location.pathname}>
								<Route path='/' exact component={HomeScreen} />
								<Route path='/aboutme' component={AboutScreen} />
								{/* <Route path='/contact' component={ContactScreen} /> */}
								<Route component={NotFound} />
							</Switch>
						</AnimatePresence>
					)}
				/>
			</main>
			<Footer />
		</Router>
	)
}

export default App
