import React, { useState } from 'react'
import Name from './Name'

const Header = () => {
	const [imgLoaded, setImageLoaded] = useState(false)

	return (
		<header>
			{/* <Nav /> */}
			<div className='intro'>
				{imgLoaded && <Name />}
				<img
					src='./images/background2.jpg'
					alt='Welcome'
					className={imgLoaded ? '' : 'hidden'}
					onLoad={() => setImageLoaded(true)}
				/>
			</div>
		</header>
	)
}

export default Header
