import React from 'react'
import { motion } from 'framer-motion'
import { apps } from './myApps'

/**
 * This is an example of animating shared layouts in Framer Motion 2.
 *
 * The open state of each panel is contained locally to that component. Wrapping
 * them all in the same AnimateSharedLayout component allows them all to animate
 * in response to state changes that affect each other's layout.
 *
 * Try removing AnimateSharedLayout to see how that affects the animation.
 */

export default function List() {
	return (
		<motion.div className='items-wrapper' layout initial={{ borderRadius: 25 }}>
			{apps.map((item) => (
				<Item key={item.id} item={item} />
			))}
		</motion.div>
	)
}

function Item({ item }) {
	return (
		<motion.div className='item'>
			<motion.h2
				initial={{ color: 'white' }}
				whileHover={{
					color: 'rgba(240, 193, 9, 1)',
				}}
			>
				{item.title}
			</motion.h2>
			<div className='images-container'>
				{item.images.map((img_url) => (
					<img key={item.id} src={img_url} alt={item.title} />
				))}
			</div>

			<div className='row'>
				<p>
					{item.description}
					<a href={item.html_url} target='_blank' rel='noreferrer'>
						Go to {item.title}
					</a>
				</p>
			</div>
		</motion.div>
	)
}
