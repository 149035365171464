import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const Footer = () => {
	return (
		<footer>
			<div>
				<Link to='/'>Adrian Karanja</Link> <span>&copy;</span>
			</div>
			<div className='social-links'>
				<motion.a
					whileHover={{
						scale: 1.1,
						ease: 'easeIn',
						color: 'dodgerblue',
					}}
					href='https://www.linkedin.com/in/adrian-karanja-b80b821a4'
					target='_blank'
				>
					<i class='fab fa-linkedin-in'></i>
				</motion.a>
				<motion.a
					whileHover={{
						scale: 1.1,
						ease: 'easeIn',
						color: 'dodgerblue',
					}}
					href='https://github.com/adriankaranja'
					target='_blank'
				>
					<i class='fab fa-github'></i>
				</motion.a>
				<motion.a
					whileHover={{
						scale: 1.1,
						ease: 'easeIn',
						color: 'dodgerblue',
					}}
					href='https://www.instagram.com/adrian.karanja/?hl=en'
					target='_blank'
				>
					<i class='fab fa-instagram'></i>
				</motion.a>
				<motion.a
					whileHover={{
						scale: 1.1,
						ease: 'easeIn',
						color: 'dodgerblue',
					}}
					href='#'
					target='_blank'
				>
					<i class='fab fa-stack-overflow'></i>
				</motion.a>
			</div>
		</footer>
	)
}

export default Footer
