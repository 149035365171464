import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

const AboutScreen = () => {
	return (
		<motion.div
			initial={{ opacity: 0, x: '-100vw', scale: 0.8 }}
			animate={{ opacity: 1, x: 0, scale: 1 }}
			exit={{ opacity: 0, x: '-100vw', scale: 1.2 }}
			transition={{ type: 'tween', ease: 'anticipate', duration: 1 }}
		>
			<div className='lg-box about'>
				<div className='about-wrapper'>
					<div className='row'>
						<h1>About Me:</h1>
						<Link to='/'>Go Back</Link>
					</div>

					<hr />

					<div className='md-container'>
						<div className='avatar'>
							<img src='./images/myAvatar2.png' alt='My Avatar' />
						</div>
						<div className='sm-container'>
							<p>
								Hello, I am Adrian Karanja, a web developer who currently
								resides in Peterborough, Ontario. I am originally from Nairobi,
								Kenya. I am currently in my third year at Trent University in
								Peterborough, Canada pursuing a degree in Computer Science. In
								my spare time I love designing and developing new web
								applications. I focus on frontend development, mainly using
								ReactJS framework, and full stack development mainly using the
								MERN and LAMP stacks.
							</p>
							<p>
								My passion for web development began a couple of years ago when
								I undertook the development of my first basic HTML site. I was
								so stunned by what I could create on my own that it fueled my
								passion. To this day I pour my heart into any project I am
								working on because I would otherwise not be satisified with the
								result.
							</p>
							<p>
								Outside of web development, I have gained knowledge and skills
								throughout my life that have been brought about my experiences
								living in several different countries. I have learned to keep an
								open mind and appreciate the wealth of knowledge and various
								cultures I have had the chance to experience. I love travelling,
								playing football, taking walks, learning new languages and
								painting.
							</p>
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	)
}

export default AboutScreen
