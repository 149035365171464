import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Fade } from 'react-reveal'
import Header from '../components/Header'
import List from '../components/List'

const HomeScreen = () => {
	return (
		<motion.div
			initial={{ opacity: 0, x: '-100vw', scale: 0.8 }}
			animate={{ opacity: 1, x: 0, scale: 1 }}
			exit={{ opacity: 0, x: '-100vw', scale: 1.2 }}
			transition={{ type: 'tween', ease: 'anticipate', duration: 1 }}
		>
			<Header />

			<div className='lg-box home'>
				<Fade left>
					<div className='md-container'>
						<div className='avatar'>
							<img src='./images/myAvatar2.png' alt='My Avatar' />
						</div>
						<div className='sm-container'>
							<p>
								Hello, I am Adrian Karanja, a web developer who currently
								resides in Peterborough, Ontario. I am originally from Nairobi,
								Kenya. I am currently in my third year at Trent University in
								Peterborough, Canada pursuing a degree in Computer Science. In
								my spare time I love designing and developing new web
								applications. I focus on frontend development, mainly using
								ReactJS framework, and full stack development mainly using the
								MERN and LAMP stacks. Click below to learn more about me.
								<Link to='/aboutme'>
									<span>Learn More</span>
								</Link>
							</p>
						</div>
					</div>
				</Fade>
				<Fade right>
					<div className='portfolio'>
						<h1>Portfolio</h1>
						<List />
					</div>
				</Fade>
				<Fade left>
					<div className='md-container contact'>
						<p>
							<i class='fas fa-id-card'></i>
							If you have any questions regarding myself or my work, feel free
							to email me @{' '}
							<a href='mailto:adriankaranja@trentu.ca'>
								adriankaranja@trentu.ca
							</a>
						</p>
					</div>
				</Fade>
			</div>
		</motion.div>
	)
}

export default HomeScreen
