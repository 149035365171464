import React from 'react'

function NotFound() {
	return (
		<div className='not-found'>
			<h1>Not Found</h1>
			<p className='lead'>The page you are looking for does not exist..</p>
		</div>
	)
}

export default NotFound
